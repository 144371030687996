// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-templates-blog-category-js": () => import("./../../../src/templates/blogCategory.js" /* webpackChunkName: "component---src-templates-blog-category-js" */),
  "component---src-templates-blog-portal-js": () => import("./../../../src/templates/blogPortal.js" /* webpackChunkName: "component---src-templates-blog-portal-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-team-member-js": () => import("./../../../src/templates/teamMember.js" /* webpackChunkName: "component---src-templates-team-member-js" */)
}

